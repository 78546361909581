.DraftEditor-root {
  border: 1px solid grey;
  border-radius: 5px;
  margin: 0 10px;
  padding: 15px;
}

.MuiFormControl-root {
  height: 52px;
}

.MuiFormControl-root.wide-input {
  width: 92%;
}
