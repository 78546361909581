.login-page::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(./img/nature-sky-sunny-clouds.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.login-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 8%;
  width: 500px;
  height: 540px;
  opacity: 0.95;
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffffed !important;

  .avatar {
    height: 105px;
    width: 105px;
    margin-bottom: 40px;
  }

  .login-form {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiFormControl-root {
      margin: 5px 0;
    }

    input {
      padding: 5px;
    }

    .btn-login {
      margin-top: 15px;
    }
  }
}
