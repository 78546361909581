.btn-download-excel {
  display: flex;
  background-color: #709d4c;
  color: white;
  height: 42px;
  width: auto;
  font-size: 15px;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  padding: 10px 15px;
  margin: 10px 30px;
  border-radius: 3px;
  /* line-height: 1; */
  cursor: pointer;
  float: right;

  &.block {
    pointer-events: none;
    opacity: 0.7;
  }
  & svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: #b2dc8f;
  }
}
